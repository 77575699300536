import GeoTIFF from 'ol/source/GeoTIFF.js';
import Map from 'ol/Map.js';
import TileLayer from 'ol/layer/WebGLTile.js';
import OSM from 'ol/source/OSM';

import LayerGroup from 'ol/layer/Group';

const source = new GeoTIFF({
  sources: [
    {
      normalize: false,
      url: 'clip/bernalillo/2000/USHAP_BC_D1K_20000131_V1.tif',
    },
  ],
});

//source is a GeoTIFF source with Float32 data from 0 to 1
//we need to normalize it to 0-255 to display it




const rawlayer = new TileLayer({
  source: source,
});
//get the first keypair from bc_dates
var start_url="clip/bernalillo/2000/USHAP_BC_D1K_20000101_V1.tif"

var map_layers = [
  new TileLayer({
    source: new GeoTIFF({
      // normalize: true,
      interpolate: false,
      sources: [
        {
          
          url: start_url,
          transition: 0,
          min: 0,
          max: 2,
          //no data value
          nodata: 0,

        },
      ],
    }),
  }),]

const map = new Map({
  target: 'map',
  layers: map_layers,
  view: source.getView(),
});
var start_date="2000-01-01"
console.log(start_date)
function updateLayer(direction) {
  
//create a new layergroup
  //convert the date string to a date so we can increment it
  start_date=new Date(start_date)
  //increment the date by one day
  if (direction === 'next') start_date.setDate(start_date.getDate() + 1);
  else start_date.setDate(start_date.getDate() - 1);
  // start_date.setDate(start_date.getDate() + 1);
  //convert the date back to a string
  start_date=start_date.toISOString().split('T')[0]
  console.log(start_date)
  
  var this_url=bc_dates[start_date]
  // Create a new GeoTIFF source with the new URL
  var newGeoTiffSource = new GeoTIFF({
    interpolate: false,
    sources: [
      {
        url: this_url,
        transition: 0,
        min: 0,
        max: 2,
        nodata: 0,
      },
    ],
  });

  // Set the new source on the layer
  map_layers[0].setSource(newGeoTiffSource);
  //there is a div <div id="date"></div> in the html
  //set the date to the new date
  document.getElementById('date').innerHTML = start_date;
  
 
}

document.getElementById('next').addEventListener('click', function() {
  updateLayer('next'); // Pass the parameter 'next' when calling updateLayer
});

document.getElementById('previous').addEventListener('click', function() {
  updateLayer('previous'); // Pass the parameter 'previous' when calling updateLayer
});


const bc_dates={
  "2000-01-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000101_V1.tif",
  "2000-01-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000102_V1.tif",
  "2000-01-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000103_V1.tif",
  "2000-01-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000104_V1.tif",
  "2000-01-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000105_V1.tif",
  "2000-01-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000106_V1.tif",
  "2000-01-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000107_V1.tif",
  "2000-01-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000108_V1.tif",
  "2000-01-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000109_V1.tif",
  "2000-01-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000110_V1.tif",
  "2000-01-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000111_V1.tif",
  "2000-01-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000112_V1.tif",
  "2000-01-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000113_V1.tif",
  "2000-01-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000114_V1.tif",
  "2000-01-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000115_V1.tif",
  "2000-01-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000116_V1.tif",
  "2000-01-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000117_V1.tif",
  "2000-01-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000118_V1.tif",
  "2000-01-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000119_V1.tif",
  "2000-01-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000120_V1.tif",
  "2000-01-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000121_V1.tif",
  "2000-01-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000122_V1.tif",
  "2000-01-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000123_V1.tif",
  "2000-01-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000124_V1.tif",
  "2000-01-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000125_V1.tif",
  "2000-01-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000126_V1.tif",
  "2000-01-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000127_V1.tif",
  "2000-01-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000128_V1.tif",
  "2000-01-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000129_V1.tif",
  "2000-01-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000130_V1.tif",
  "2000-01-31": "clip/bernalillo/2000/USHAP_BC_D1K_20000131_V1.tif",
  "2000-02-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000201_V1.tif",
  "2000-02-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000202_V1.tif",
  "2000-02-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000203_V1.tif",
  "2000-02-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000204_V1.tif",
  "2000-02-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000205_V1.tif",
  "2000-02-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000206_V1.tif",
  "2000-02-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000207_V1.tif",
  "2000-02-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000208_V1.tif",
  "2000-02-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000209_V1.tif",
  "2000-02-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000210_V1.tif",
  "2000-02-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000211_V1.tif",
  "2000-02-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000212_V1.tif",
  "2000-02-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000213_V1.tif",
  "2000-02-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000214_V1.tif",
  "2000-02-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000215_V1.tif",
  "2000-02-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000216_V1.tif",
  "2000-02-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000217_V1.tif",
  "2000-02-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000218_V1.tif",
  "2000-02-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000219_V1.tif",
  "2000-02-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000220_V1.tif",
  "2000-02-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000221_V1.tif",
  "2000-02-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000222_V1.tif",
  "2000-02-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000223_V1.tif",
  "2000-02-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000224_V1.tif",
  "2000-02-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000225_V1.tif",
  "2000-02-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000226_V1.tif",
  "2000-02-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000227_V1.tif",
  "2000-02-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000228_V1.tif",
  "2000-02-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000229_V1.tif",
  "2000-03-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000301_V1.tif",
  "2000-03-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000302_V1.tif",
  "2000-03-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000303_V1.tif",
  "2000-03-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000304_V1.tif",
  "2000-03-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000305_V1.tif",
  "2000-03-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000306_V1.tif",
  "2000-03-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000307_V1.tif",
  "2000-03-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000308_V1.tif",
  "2000-03-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000309_V1.tif",
  "2000-03-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000310_V1.tif",
  "2000-03-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000311_V1.tif",
  "2000-03-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000312_V1.tif",
  "2000-03-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000313_V1.tif",
  "2000-03-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000314_V1.tif",
  "2000-03-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000315_V1.tif",
  "2000-03-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000316_V1.tif",
  "2000-03-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000317_V1.tif",
  "2000-03-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000318_V1.tif",
  "2000-03-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000319_V1.tif",
  "2000-03-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000320_V1.tif",
  "2000-03-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000321_V1.tif",
  "2000-03-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000322_V1.tif",
  "2000-03-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000323_V1.tif",
  "2000-03-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000324_V1.tif",
  "2000-03-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000325_V1.tif",
  "2000-03-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000326_V1.tif",
  "2000-03-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000327_V1.tif",
  "2000-03-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000328_V1.tif",
  "2000-03-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000329_V1.tif",
  "2000-03-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000330_V1.tif",
  "2000-03-31": "clip/bernalillo/2000/USHAP_BC_D1K_20000331_V1.tif",
  "2000-04-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000401_V1.tif",
  "2000-04-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000402_V1.tif",
  "2000-04-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000403_V1.tif",
  "2000-04-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000404_V1.tif",
  "2000-04-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000405_V1.tif",
  "2000-04-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000406_V1.tif",
  "2000-04-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000407_V1.tif",
  "2000-04-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000408_V1.tif",
  "2000-04-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000409_V1.tif",
  "2000-04-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000410_V1.tif",
  "2000-04-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000411_V1.tif",
  "2000-04-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000412_V1.tif",
  "2000-04-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000413_V1.tif",
  "2000-04-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000414_V1.tif",
  "2000-04-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000415_V1.tif",
  "2000-04-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000416_V1.tif",
  "2000-04-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000417_V1.tif",
  "2000-04-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000418_V1.tif",
  "2000-04-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000419_V1.tif",
  "2000-04-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000420_V1.tif",
  "2000-04-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000421_V1.tif",
  "2000-04-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000422_V1.tif",
  "2000-04-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000423_V1.tif",
  "2000-04-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000424_V1.tif",
  "2000-04-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000425_V1.tif",
  "2000-04-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000426_V1.tif",
  "2000-04-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000427_V1.tif",
  "2000-04-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000428_V1.tif",
  "2000-04-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000429_V1.tif",
  "2000-04-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000430_V1.tif",
  "2000-05-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000501_V1.tif",
  "2000-05-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000502_V1.tif",
  "2000-05-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000503_V1.tif",
  "2000-05-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000504_V1.tif",
  "2000-05-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000505_V1.tif",
  "2000-05-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000506_V1.tif",
  "2000-05-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000507_V1.tif",
  "2000-05-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000508_V1.tif",
  "2000-05-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000509_V1.tif",
  "2000-05-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000510_V1.tif",
  "2000-05-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000511_V1.tif",
  "2000-05-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000512_V1.tif",
  "2000-05-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000513_V1.tif",
  "2000-05-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000514_V1.tif",
  "2000-05-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000515_V1.tif",
  "2000-05-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000516_V1.tif",
  "2000-05-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000517_V1.tif",
  "2000-05-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000518_V1.tif",
  "2000-05-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000519_V1.tif",
  "2000-05-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000520_V1.tif",
  "2000-05-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000521_V1.tif",
  "2000-05-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000522_V1.tif",
  "2000-05-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000523_V1.tif",
  "2000-05-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000524_V1.tif",
  "2000-05-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000525_V1.tif",
  "2000-05-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000526_V1.tif",
  "2000-05-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000527_V1.tif",
  "2000-05-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000528_V1.tif",
  "2000-05-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000529_V1.tif",
  "2000-05-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000530_V1.tif",
  "2000-05-31": "clip/bernalillo/2000/USHAP_BC_D1K_20000531_V1.tif",
  "2000-06-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000601_V1.tif",
  "2000-06-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000602_V1.tif",
  "2000-06-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000603_V1.tif",
  "2000-06-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000604_V1.tif",
  "2000-06-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000605_V1.tif",
  "2000-06-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000606_V1.tif",
  "2000-06-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000607_V1.tif",
  "2000-06-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000608_V1.tif",
  "2000-06-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000609_V1.tif",
  "2000-06-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000610_V1.tif",
  "2000-06-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000611_V1.tif",
  "2000-06-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000612_V1.tif",
  "2000-06-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000613_V1.tif",
  "2000-06-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000614_V1.tif",
  "2000-06-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000615_V1.tif",
  "2000-06-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000616_V1.tif",
  "2000-06-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000617_V1.tif",
  "2000-06-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000618_V1.tif",
  "2000-06-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000619_V1.tif",
  "2000-06-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000620_V1.tif",
  "2000-06-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000621_V1.tif",
  "2000-06-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000622_V1.tif",
  "2000-06-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000623_V1.tif",
  "2000-06-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000624_V1.tif",
  "2000-06-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000625_V1.tif",
  "2000-06-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000626_V1.tif",
  "2000-06-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000627_V1.tif",
  "2000-06-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000628_V1.tif",
  "2000-06-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000629_V1.tif",
  "2000-06-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000630_V1.tif",
  "2000-07-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000701_V1.tif",
  "2000-07-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000702_V1.tif",
  "2000-07-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000703_V1.tif",
  "2000-07-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000704_V1.tif",
  "2000-07-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000705_V1.tif",
  "2000-07-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000706_V1.tif",
  "2000-07-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000707_V1.tif",
  "2000-07-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000708_V1.tif",
  "2000-07-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000709_V1.tif",
  "2000-07-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000710_V1.tif",
  "2000-07-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000711_V1.tif",
  "2000-07-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000712_V1.tif",
  "2000-07-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000713_V1.tif",
  "2000-07-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000714_V1.tif",
  "2000-07-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000715_V1.tif",
  "2000-07-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000716_V1.tif",
  "2000-07-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000717_V1.tif",
  "2000-07-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000718_V1.tif",
  "2000-07-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000719_V1.tif",
  "2000-07-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000720_V1.tif",
  "2000-07-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000721_V1.tif",
  "2000-07-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000722_V1.tif",
  "2000-07-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000723_V1.tif",
  "2000-07-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000724_V1.tif",
  "2000-07-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000725_V1.tif",
  "2000-07-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000726_V1.tif",
  "2000-07-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000727_V1.tif",
  "2000-07-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000728_V1.tif",
  "2000-07-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000729_V1.tif",
  "2000-07-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000730_V1.tif",
  "2000-07-31": "clip/bernalillo/2000/USHAP_BC_D1K_20000731_V1.tif",
  "2000-08-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000801_V1.tif",
  "2000-08-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000802_V1.tif",
  "2000-08-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000803_V1.tif",
  "2000-08-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000804_V1.tif",
  "2000-08-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000805_V1.tif",
  "2000-08-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000806_V1.tif",
  "2000-08-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000807_V1.tif",
  "2000-08-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000808_V1.tif",
  "2000-08-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000809_V1.tif",
  "2000-08-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000810_V1.tif",
  "2000-08-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000811_V1.tif",
  "2000-08-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000812_V1.tif",
  "2000-08-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000813_V1.tif",
  "2000-08-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000814_V1.tif",
  "2000-08-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000815_V1.tif",
  "2000-08-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000816_V1.tif",
  "2000-08-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000817_V1.tif",
  "2000-08-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000818_V1.tif",
  "2000-08-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000819_V1.tif",
  "2000-08-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000820_V1.tif",
  "2000-08-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000821_V1.tif",
  "2000-08-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000822_V1.tif",
  "2000-08-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000823_V1.tif",
  "2000-08-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000824_V1.tif",
  "2000-08-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000825_V1.tif",
  "2000-08-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000826_V1.tif",
  "2000-08-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000827_V1.tif",
  "2000-08-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000828_V1.tif",
  "2000-08-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000829_V1.tif",
  "2000-08-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000830_V1.tif",
  "2000-08-31": "clip/bernalillo/2000/USHAP_BC_D1K_20000831_V1.tif",
  "2000-09-01": "clip/bernalillo/2000/USHAP_BC_D1K_20000901_V1.tif",
  "2000-09-02": "clip/bernalillo/2000/USHAP_BC_D1K_20000902_V1.tif",
  "2000-09-03": "clip/bernalillo/2000/USHAP_BC_D1K_20000903_V1.tif",
  "2000-09-04": "clip/bernalillo/2000/USHAP_BC_D1K_20000904_V1.tif",
  "2000-09-05": "clip/bernalillo/2000/USHAP_BC_D1K_20000905_V1.tif",
  "2000-09-06": "clip/bernalillo/2000/USHAP_BC_D1K_20000906_V1.tif",
  "2000-09-07": "clip/bernalillo/2000/USHAP_BC_D1K_20000907_V1.tif",
  "2000-09-08": "clip/bernalillo/2000/USHAP_BC_D1K_20000908_V1.tif",
  "2000-09-09": "clip/bernalillo/2000/USHAP_BC_D1K_20000909_V1.tif",
  "2000-09-10": "clip/bernalillo/2000/USHAP_BC_D1K_20000910_V1.tif",
  "2000-09-11": "clip/bernalillo/2000/USHAP_BC_D1K_20000911_V1.tif",
  "2000-09-12": "clip/bernalillo/2000/USHAP_BC_D1K_20000912_V1.tif",
  "2000-09-13": "clip/bernalillo/2000/USHAP_BC_D1K_20000913_V1.tif",
  "2000-09-14": "clip/bernalillo/2000/USHAP_BC_D1K_20000914_V1.tif",
  "2000-09-15": "clip/bernalillo/2000/USHAP_BC_D1K_20000915_V1.tif",
  "2000-09-16": "clip/bernalillo/2000/USHAP_BC_D1K_20000916_V1.tif",
  "2000-09-17": "clip/bernalillo/2000/USHAP_BC_D1K_20000917_V1.tif",
  "2000-09-18": "clip/bernalillo/2000/USHAP_BC_D1K_20000918_V1.tif",
  "2000-09-19": "clip/bernalillo/2000/USHAP_BC_D1K_20000919_V1.tif",
  "2000-09-20": "clip/bernalillo/2000/USHAP_BC_D1K_20000920_V1.tif",
  "2000-09-21": "clip/bernalillo/2000/USHAP_BC_D1K_20000921_V1.tif",
  "2000-09-22": "clip/bernalillo/2000/USHAP_BC_D1K_20000922_V1.tif",
  "2000-09-23": "clip/bernalillo/2000/USHAP_BC_D1K_20000923_V1.tif",
  "2000-09-24": "clip/bernalillo/2000/USHAP_BC_D1K_20000924_V1.tif",
  "2000-09-25": "clip/bernalillo/2000/USHAP_BC_D1K_20000925_V1.tif",
  "2000-09-26": "clip/bernalillo/2000/USHAP_BC_D1K_20000926_V1.tif",
  "2000-09-27": "clip/bernalillo/2000/USHAP_BC_D1K_20000927_V1.tif",
  "2000-09-28": "clip/bernalillo/2000/USHAP_BC_D1K_20000928_V1.tif",
  "2000-09-29": "clip/bernalillo/2000/USHAP_BC_D1K_20000929_V1.tif",
  "2000-09-30": "clip/bernalillo/2000/USHAP_BC_D1K_20000930_V1.tif",
  "2000-10-01": "clip/bernalillo/2000/USHAP_BC_D1K_20001001_V1.tif",
  "2000-10-02": "clip/bernalillo/2000/USHAP_BC_D1K_20001002_V1.tif",
  "2000-10-03": "clip/bernalillo/2000/USHAP_BC_D1K_20001003_V1.tif",
  "2000-10-04": "clip/bernalillo/2000/USHAP_BC_D1K_20001004_V1.tif",
  "2000-10-05": "clip/bernalillo/2000/USHAP_BC_D1K_20001005_V1.tif",
  "2000-10-06": "clip/bernalillo/2000/USHAP_BC_D1K_20001006_V1.tif",
  "2000-10-07": "clip/bernalillo/2000/USHAP_BC_D1K_20001007_V1.tif",
  "2000-10-08": "clip/bernalillo/2000/USHAP_BC_D1K_20001008_V1.tif",
  "2000-10-09": "clip/bernalillo/2000/USHAP_BC_D1K_20001009_V1.tif",
  "2000-10-10": "clip/bernalillo/2000/USHAP_BC_D1K_20001010_V1.tif",
  "2000-10-11": "clip/bernalillo/2000/USHAP_BC_D1K_20001011_V1.tif",
  "2000-10-12": "clip/bernalillo/2000/USHAP_BC_D1K_20001012_V1.tif",
  "2000-10-13": "clip/bernalillo/2000/USHAP_BC_D1K_20001013_V1.tif",
  "2000-10-14": "clip/bernalillo/2000/USHAP_BC_D1K_20001014_V1.tif",
  "2000-10-15": "clip/bernalillo/2000/USHAP_BC_D1K_20001015_V1.tif",
  "2000-10-16": "clip/bernalillo/2000/USHAP_BC_D1K_20001016_V1.tif",
  "2000-10-17": "clip/bernalillo/2000/USHAP_BC_D1K_20001017_V1.tif",
  "2000-10-18": "clip/bernalillo/2000/USHAP_BC_D1K_20001018_V1.tif",
  "2000-10-19": "clip/bernalillo/2000/USHAP_BC_D1K_20001019_V1.tif",
  "2000-10-20": "clip/bernalillo/2000/USHAP_BC_D1K_20001020_V1.tif",
  "2000-10-21": "clip/bernalillo/2000/USHAP_BC_D1K_20001021_V1.tif",
  "2000-10-22": "clip/bernalillo/2000/USHAP_BC_D1K_20001022_V1.tif",
  "2000-10-23": "clip/bernalillo/2000/USHAP_BC_D1K_20001023_V1.tif",
  "2000-10-24": "clip/bernalillo/2000/USHAP_BC_D1K_20001024_V1.tif",
  "2000-10-25": "clip/bernalillo/2000/USHAP_BC_D1K_20001025_V1.tif",
  "2000-10-26": "clip/bernalillo/2000/USHAP_BC_D1K_20001026_V1.tif",
  "2000-10-27": "clip/bernalillo/2000/USHAP_BC_D1K_20001027_V1.tif",
  "2000-10-28": "clip/bernalillo/2000/USHAP_BC_D1K_20001028_V1.tif",
  "2000-10-29": "clip/bernalillo/2000/USHAP_BC_D1K_20001029_V1.tif",
  "2000-10-30": "clip/bernalillo/2000/USHAP_BC_D1K_20001030_V1.tif",
  "2000-10-31": "clip/bernalillo/2000/USHAP_BC_D1K_20001031_V1.tif",
  "2000-11-01": "clip/bernalillo/2000/USHAP_BC_D1K_20001101_V1.tif",
  "2000-11-02": "clip/bernalillo/2000/USHAP_BC_D1K_20001102_V1.tif",
  "2000-11-03": "clip/bernalillo/2000/USHAP_BC_D1K_20001103_V1.tif",
  "2000-11-04": "clip/bernalillo/2000/USHAP_BC_D1K_20001104_V1.tif",
  "2000-11-05": "clip/bernalillo/2000/USHAP_BC_D1K_20001105_V1.tif",
  "2000-11-06": "clip/bernalillo/2000/USHAP_BC_D1K_20001106_V1.tif",
  "2000-11-07": "clip/bernalillo/2000/USHAP_BC_D1K_20001107_V1.tif",
  "2000-11-08": "clip/bernalillo/2000/USHAP_BC_D1K_20001108_V1.tif",
  "2000-11-09": "clip/bernalillo/2000/USHAP_BC_D1K_20001109_V1.tif",
  "2000-11-10": "clip/bernalillo/2000/USHAP_BC_D1K_20001110_V1.tif",
  "2000-11-11": "clip/bernalillo/2000/USHAP_BC_D1K_20001111_V1.tif",
  "2000-11-12": "clip/bernalillo/2000/USHAP_BC_D1K_20001112_V1.tif",
  "2000-11-13": "clip/bernalillo/2000/USHAP_BC_D1K_20001113_V1.tif",
  "2000-11-14": "clip/bernalillo/2000/USHAP_BC_D1K_20001114_V1.tif",
  "2000-11-15": "clip/bernalillo/2000/USHAP_BC_D1K_20001115_V1.tif",
  "2000-11-16": "clip/bernalillo/2000/USHAP_BC_D1K_20001116_V1.tif",
  "2000-11-17": "clip/bernalillo/2000/USHAP_BC_D1K_20001117_V1.tif",
  "2000-11-18": "clip/bernalillo/2000/USHAP_BC_D1K_20001118_V1.tif",
  "2000-11-19": "clip/bernalillo/2000/USHAP_BC_D1K_20001119_V1.tif",
  "2000-11-20": "clip/bernalillo/2000/USHAP_BC_D1K_20001120_V1.tif",
  "2000-11-21": "clip/bernalillo/2000/USHAP_BC_D1K_20001121_V1.tif",
  "2000-11-22": "clip/bernalillo/2000/USHAP_BC_D1K_20001122_V1.tif",
  "2000-11-23": "clip/bernalillo/2000/USHAP_BC_D1K_20001123_V1.tif",
  "2000-11-24": "clip/bernalillo/2000/USHAP_BC_D1K_20001124_V1.tif",
  "2000-11-25": "clip/bernalillo/2000/USHAP_BC_D1K_20001125_V1.tif",
  "2000-11-26": "clip/bernalillo/2000/USHAP_BC_D1K_20001126_V1.tif",
  "2000-11-27": "clip/bernalillo/2000/USHAP_BC_D1K_20001127_V1.tif",
  "2000-11-28": "clip/bernalillo/2000/USHAP_BC_D1K_20001128_V1.tif",
  "2000-11-29": "clip/bernalillo/2000/USHAP_BC_D1K_20001129_V1.tif",
  "2000-11-30": "clip/bernalillo/2000/USHAP_BC_D1K_20001130_V1.tif",
  "2000-12-01": "clip/bernalillo/2000/USHAP_BC_D1K_20001201_V1.tif",
  "2000-12-02": "clip/bernalillo/2000/USHAP_BC_D1K_20001202_V1.tif",
  "2000-12-03": "clip/bernalillo/2000/USHAP_BC_D1K_20001203_V1.tif",
  "2000-12-04": "clip/bernalillo/2000/USHAP_BC_D1K_20001204_V1.tif",
  "2000-12-05": "clip/bernalillo/2000/USHAP_BC_D1K_20001205_V1.tif",
  "2000-12-06": "clip/bernalillo/2000/USHAP_BC_D1K_20001206_V1.tif",
  "2000-12-07": "clip/bernalillo/2000/USHAP_BC_D1K_20001207_V1.tif",
  "2000-12-08": "clip/bernalillo/2000/USHAP_BC_D1K_20001208_V1.tif",
  "2000-12-09": "clip/bernalillo/2000/USHAP_BC_D1K_20001209_V1.tif",
  "2000-12-10": "clip/bernalillo/2000/USHAP_BC_D1K_20001210_V1.tif",
  "2000-12-11": "clip/bernalillo/2000/USHAP_BC_D1K_20001211_V1.tif",
  "2000-12-12": "clip/bernalillo/2000/USHAP_BC_D1K_20001212_V1.tif",
  "2000-12-13": "clip/bernalillo/2000/USHAP_BC_D1K_20001213_V1.tif",
  "2000-12-14": "clip/bernalillo/2000/USHAP_BC_D1K_20001214_V1.tif",
  "2000-12-15": "clip/bernalillo/2000/USHAP_BC_D1K_20001215_V1.tif",
  "2000-12-16": "clip/bernalillo/2000/USHAP_BC_D1K_20001216_V1.tif",
  "2000-12-17": "clip/bernalillo/2000/USHAP_BC_D1K_20001217_V1.tif",
  "2000-12-18": "clip/bernalillo/2000/USHAP_BC_D1K_20001218_V1.tif",
  "2000-12-19": "clip/bernalillo/2000/USHAP_BC_D1K_20001219_V1.tif",
  "2000-12-20": "clip/bernalillo/2000/USHAP_BC_D1K_20001220_V1.tif",
  "2000-12-21": "clip/bernalillo/2000/USHAP_BC_D1K_20001221_V1.tif",
  "2000-12-22": "clip/bernalillo/2000/USHAP_BC_D1K_20001222_V1.tif",
  "2000-12-23": "clip/bernalillo/2000/USHAP_BC_D1K_20001223_V1.tif",
  "2000-12-24": "clip/bernalillo/2000/USHAP_BC_D1K_20001224_V1.tif",
  "2000-12-25": "clip/bernalillo/2000/USHAP_BC_D1K_20001225_V1.tif",
  "2000-12-26": "clip/bernalillo/2000/USHAP_BC_D1K_20001226_V1.tif",
  "2000-12-27": "clip/bernalillo/2000/USHAP_BC_D1K_20001227_V1.tif",
  "2000-12-28": "clip/bernalillo/2000/USHAP_BC_D1K_20001228_V1.tif",
  "2000-12-29": "clip/bernalillo/2000/USHAP_BC_D1K_20001229_V1.tif",
  "2000-12-30": "clip/bernalillo/2000/USHAP_BC_D1K_20001230_V1.tif",
  "2000-12-31": "clip/bernalillo/2000/USHAP_BC_D1K_20001231_V1.tif",
  "2001-01-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010101_V1.tif",
  "2001-01-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010102_V1.tif",
  "2001-01-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010103_V1.tif",
  "2001-01-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010104_V1.tif",
  "2001-01-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010105_V1.tif",
  "2001-01-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010106_V1.tif",
  "2001-01-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010107_V1.tif",
  "2001-01-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010108_V1.tif",
  "2001-01-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010109_V1.tif",
  "2001-01-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010110_V1.tif",
  "2001-01-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010111_V1.tif",
  "2001-01-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010112_V1.tif",
  "2001-01-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010113_V1.tif",
  "2001-01-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010114_V1.tif",
  "2001-01-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010115_V1.tif",
  "2001-01-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010116_V1.tif",
  "2001-01-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010117_V1.tif",
  "2001-01-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010118_V1.tif",
  "2001-01-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010119_V1.tif",
  "2001-01-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010120_V1.tif",
  "2001-01-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010121_V1.tif",
  "2001-01-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010122_V1.tif",
  "2001-01-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010123_V1.tif",
  "2001-01-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010124_V1.tif",
  "2001-01-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010125_V1.tif",
  "2001-01-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010126_V1.tif",
  "2001-01-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010127_V1.tif",
  "2001-01-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010128_V1.tif",
  "2001-01-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010129_V1.tif",
  "2001-01-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010130_V1.tif",
  "2001-01-31": "clip/bernalillo/2001/USHAP_BC_D1K_20010131_V1.tif",
  "2001-02-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010201_V1.tif",
  "2001-02-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010202_V1.tif",
  "2001-02-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010203_V1.tif",
  "2001-02-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010204_V1.tif",
  "2001-02-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010205_V1.tif",
  "2001-02-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010206_V1.tif",
  "2001-02-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010207_V1.tif",
  "2001-02-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010208_V1.tif",
  "2001-02-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010209_V1.tif",
  "2001-02-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010210_V1.tif",
  "2001-02-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010211_V1.tif",
  "2001-02-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010212_V1.tif",
  "2001-02-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010213_V1.tif",
  "2001-02-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010214_V1.tif",
  "2001-02-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010215_V1.tif",
  "2001-02-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010216_V1.tif",
  "2001-02-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010217_V1.tif",
  "2001-02-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010218_V1.tif",
  "2001-02-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010219_V1.tif",
  "2001-02-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010220_V1.tif",
  "2001-02-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010221_V1.tif",
  "2001-02-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010222_V1.tif",
  "2001-02-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010223_V1.tif",
  "2001-02-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010224_V1.tif",
  "2001-02-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010225_V1.tif",
  "2001-02-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010226_V1.tif",
  "2001-02-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010227_V1.tif",
  "2001-02-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010228_V1.tif",
  "2001-03-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010301_V1.tif",
  "2001-03-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010302_V1.tif",
  "2001-03-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010303_V1.tif",
  "2001-03-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010304_V1.tif",
  "2001-03-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010305_V1.tif",
  "2001-03-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010306_V1.tif",
  "2001-03-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010307_V1.tif",
  "2001-03-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010308_V1.tif",
  "2001-03-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010309_V1.tif",
  "2001-03-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010310_V1.tif",
  "2001-03-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010311_V1.tif",
  "2001-03-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010312_V1.tif",
  "2001-03-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010313_V1.tif",
  "2001-03-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010314_V1.tif",
  "2001-03-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010315_V1.tif",
  "2001-03-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010316_V1.tif",
  "2001-03-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010317_V1.tif",
  "2001-03-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010318_V1.tif",
  "2001-03-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010319_V1.tif",
  "2001-03-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010320_V1.tif",
  "2001-03-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010321_V1.tif",
  "2001-03-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010322_V1.tif",
  "2001-03-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010323_V1.tif",
  "2001-03-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010324_V1.tif",
  "2001-03-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010325_V1.tif",
  "2001-03-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010326_V1.tif",
  "2001-03-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010327_V1.tif",
  "2001-03-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010328_V1.tif",
  "2001-03-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010329_V1.tif",
  "2001-03-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010330_V1.tif",
  "2001-03-31": "clip/bernalillo/2001/USHAP_BC_D1K_20010331_V1.tif",
  "2001-04-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010401_V1.tif",
  "2001-04-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010402_V1.tif",
  "2001-04-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010403_V1.tif",
  "2001-04-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010404_V1.tif",
  "2001-04-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010405_V1.tif",
  "2001-04-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010406_V1.tif",
  "2001-04-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010407_V1.tif",
  "2001-04-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010408_V1.tif",
  "2001-04-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010409_V1.tif",
  "2001-04-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010410_V1.tif",
  "2001-04-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010411_V1.tif",
  "2001-04-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010412_V1.tif",
  "2001-04-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010413_V1.tif",
  "2001-04-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010414_V1.tif",
  "2001-04-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010415_V1.tif",
  "2001-04-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010416_V1.tif",
  "2001-04-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010417_V1.tif",
  "2001-04-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010418_V1.tif",
  "2001-04-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010419_V1.tif",
  "2001-04-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010420_V1.tif",
  "2001-04-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010421_V1.tif",
  "2001-04-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010422_V1.tif",
  "2001-04-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010423_V1.tif",
  "2001-04-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010424_V1.tif",
  "2001-04-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010425_V1.tif",
  "2001-04-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010426_V1.tif",
  "2001-04-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010427_V1.tif",
  "2001-04-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010428_V1.tif",
  "2001-04-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010429_V1.tif",
  "2001-04-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010430_V1.tif",
  "2001-05-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010501_V1.tif",
  "2001-05-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010502_V1.tif",
  "2001-05-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010503_V1.tif",
  "2001-05-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010504_V1.tif",
  "2001-05-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010505_V1.tif",
  "2001-05-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010506_V1.tif",
  "2001-05-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010507_V1.tif",
  "2001-05-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010508_V1.tif",
  "2001-05-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010509_V1.tif",
  "2001-05-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010510_V1.tif",
  "2001-05-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010511_V1.tif",
  "2001-05-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010512_V1.tif",
  "2001-05-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010513_V1.tif",
  "2001-05-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010514_V1.tif",
  "2001-05-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010515_V1.tif",
  "2001-05-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010516_V1.tif",
  "2001-05-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010517_V1.tif",
  "2001-05-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010518_V1.tif",
  "2001-05-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010519_V1.tif",
  "2001-05-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010520_V1.tif",
  "2001-05-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010521_V1.tif",
  "2001-05-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010522_V1.tif",
  "2001-05-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010523_V1.tif",
  "2001-05-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010524_V1.tif",
  "2001-05-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010525_V1.tif",
  "2001-05-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010526_V1.tif",
  "2001-05-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010527_V1.tif",
  "2001-05-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010528_V1.tif",
  "2001-05-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010529_V1.tif",
  "2001-05-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010530_V1.tif",
  "2001-05-31": "clip/bernalillo/2001/USHAP_BC_D1K_20010531_V1.tif",
  "2001-06-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010601_V1.tif",
  "2001-06-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010602_V1.tif",
  "2001-06-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010603_V1.tif",
  "2001-06-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010604_V1.tif",
  "2001-06-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010605_V1.tif",
  "2001-06-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010606_V1.tif",
  "2001-06-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010607_V1.tif",
  "2001-06-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010608_V1.tif",
  "2001-06-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010609_V1.tif",
  "2001-06-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010610_V1.tif",
  "2001-06-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010611_V1.tif",
  "2001-06-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010612_V1.tif",
  "2001-06-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010613_V1.tif",
  "2001-06-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010614_V1.tif",
  "2001-06-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010615_V1.tif",
  "2001-06-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010616_V1.tif",
  "2001-06-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010617_V1.tif",
  "2001-06-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010618_V1.tif",
  "2001-06-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010619_V1.tif",
  "2001-06-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010620_V1.tif",
  "2001-06-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010621_V1.tif",
  "2001-06-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010622_V1.tif",
  "2001-06-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010623_V1.tif",
  "2001-06-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010624_V1.tif",
  "2001-06-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010625_V1.tif",
  "2001-06-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010626_V1.tif",
  "2001-06-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010627_V1.tif",
  "2001-06-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010628_V1.tif",
  "2001-06-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010629_V1.tif",
  "2001-06-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010630_V1.tif",
  "2001-07-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010701_V1.tif",
  "2001-07-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010702_V1.tif",
  "2001-07-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010703_V1.tif",
  "2001-07-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010704_V1.tif",
  "2001-07-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010705_V1.tif",
  "2001-07-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010706_V1.tif",
  "2001-07-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010707_V1.tif",
  "2001-07-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010708_V1.tif",
  "2001-07-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010709_V1.tif",
  "2001-07-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010710_V1.tif",
  "2001-07-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010711_V1.tif",
  "2001-07-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010712_V1.tif",
  "2001-07-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010713_V1.tif",
  "2001-07-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010714_V1.tif",
  "2001-07-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010715_V1.tif",
  "2001-07-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010716_V1.tif",
  "2001-07-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010717_V1.tif",
  "2001-07-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010718_V1.tif",
  "2001-07-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010719_V1.tif",
  "2001-07-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010720_V1.tif",
  "2001-07-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010721_V1.tif",
  "2001-07-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010722_V1.tif",
  "2001-07-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010723_V1.tif",
  "2001-07-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010724_V1.tif",
  "2001-07-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010725_V1.tif",
  "2001-07-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010726_V1.tif",
  "2001-07-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010727_V1.tif",
  "2001-07-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010728_V1.tif",
  "2001-07-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010729_V1.tif",
  "2001-07-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010730_V1.tif",
  "2001-07-31": "clip/bernalillo/2001/USHAP_BC_D1K_20010731_V1.tif",
  "2001-08-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010801_V1.tif",
  "2001-08-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010802_V1.tif",
  "2001-08-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010803_V1.tif",
  "2001-08-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010804_V1.tif",
  "2001-08-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010805_V1.tif",
  "2001-08-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010806_V1.tif",
  "2001-08-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010807_V1.tif",
  "2001-08-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010808_V1.tif",
  "2001-08-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010809_V1.tif",
  "2001-08-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010810_V1.tif",
  "2001-08-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010811_V1.tif",
  "2001-08-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010812_V1.tif",
  "2001-08-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010813_V1.tif",
  "2001-08-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010814_V1.tif",
  "2001-08-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010815_V1.tif",
  "2001-08-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010816_V1.tif",
  "2001-08-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010817_V1.tif",
  "2001-08-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010818_V1.tif",
  "2001-08-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010819_V1.tif",
  "2001-08-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010820_V1.tif",
  "2001-08-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010821_V1.tif",
  "2001-08-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010822_V1.tif",
  "2001-08-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010823_V1.tif",
  "2001-08-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010824_V1.tif",
  "2001-08-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010825_V1.tif",
  "2001-08-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010826_V1.tif",
  "2001-08-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010827_V1.tif",
  "2001-08-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010828_V1.tif",
  "2001-08-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010829_V1.tif",
  "2001-08-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010830_V1.tif",
  "2001-08-31": "clip/bernalillo/2001/USHAP_BC_D1K_20010831_V1.tif",
  "2001-09-01": "clip/bernalillo/2001/USHAP_BC_D1K_20010901_V1.tif",
  "2001-09-02": "clip/bernalillo/2001/USHAP_BC_D1K_20010902_V1.tif",
  "2001-09-03": "clip/bernalillo/2001/USHAP_BC_D1K_20010903_V1.tif",
  "2001-09-04": "clip/bernalillo/2001/USHAP_BC_D1K_20010904_V1.tif",
  "2001-09-05": "clip/bernalillo/2001/USHAP_BC_D1K_20010905_V1.tif",
  "2001-09-06": "clip/bernalillo/2001/USHAP_BC_D1K_20010906_V1.tif",
  "2001-09-07": "clip/bernalillo/2001/USHAP_BC_D1K_20010907_V1.tif",
  "2001-09-08": "clip/bernalillo/2001/USHAP_BC_D1K_20010908_V1.tif",
  "2001-09-09": "clip/bernalillo/2001/USHAP_BC_D1K_20010909_V1.tif",
  "2001-09-10": "clip/bernalillo/2001/USHAP_BC_D1K_20010910_V1.tif",
  "2001-09-11": "clip/bernalillo/2001/USHAP_BC_D1K_20010911_V1.tif",
  "2001-09-12": "clip/bernalillo/2001/USHAP_BC_D1K_20010912_V1.tif",
  "2001-09-13": "clip/bernalillo/2001/USHAP_BC_D1K_20010913_V1.tif",
  "2001-09-14": "clip/bernalillo/2001/USHAP_BC_D1K_20010914_V1.tif",
  "2001-09-15": "clip/bernalillo/2001/USHAP_BC_D1K_20010915_V1.tif",
  "2001-09-16": "clip/bernalillo/2001/USHAP_BC_D1K_20010916_V1.tif",
  "2001-09-17": "clip/bernalillo/2001/USHAP_BC_D1K_20010917_V1.tif",
  "2001-09-18": "clip/bernalillo/2001/USHAP_BC_D1K_20010918_V1.tif",
  "2001-09-19": "clip/bernalillo/2001/USHAP_BC_D1K_20010919_V1.tif",
  "2001-09-20": "clip/bernalillo/2001/USHAP_BC_D1K_20010920_V1.tif",
  "2001-09-21": "clip/bernalillo/2001/USHAP_BC_D1K_20010921_V1.tif",
  "2001-09-22": "clip/bernalillo/2001/USHAP_BC_D1K_20010922_V1.tif",
  "2001-09-23": "clip/bernalillo/2001/USHAP_BC_D1K_20010923_V1.tif",
  "2001-09-24": "clip/bernalillo/2001/USHAP_BC_D1K_20010924_V1.tif",
  "2001-09-25": "clip/bernalillo/2001/USHAP_BC_D1K_20010925_V1.tif",
  "2001-09-26": "clip/bernalillo/2001/USHAP_BC_D1K_20010926_V1.tif",
  "2001-09-27": "clip/bernalillo/2001/USHAP_BC_D1K_20010927_V1.tif",
  "2001-09-28": "clip/bernalillo/2001/USHAP_BC_D1K_20010928_V1.tif",
  "2001-09-29": "clip/bernalillo/2001/USHAP_BC_D1K_20010929_V1.tif",
  "2001-09-30": "clip/bernalillo/2001/USHAP_BC_D1K_20010930_V1.tif",
  "2001-10-01": "clip/bernalillo/2001/USHAP_BC_D1K_20011001_V1.tif",
  "2001-10-02": "clip/bernalillo/2001/USHAP_BC_D1K_20011002_V1.tif",
  "2001-10-03": "clip/bernalillo/2001/USHAP_BC_D1K_20011003_V1.tif",
  "2001-10-04": "clip/bernalillo/2001/USHAP_BC_D1K_20011004_V1.tif",
  "2001-10-05": "clip/bernalillo/2001/USHAP_BC_D1K_20011005_V1.tif",
  "2001-10-06": "clip/bernalillo/2001/USHAP_BC_D1K_20011006_V1.tif",
  "2001-10-07": "clip/bernalillo/2001/USHAP_BC_D1K_20011007_V1.tif",
  "2001-10-08": "clip/bernalillo/2001/USHAP_BC_D1K_20011008_V1.tif",
  "2001-10-09": "clip/bernalillo/2001/USHAP_BC_D1K_20011009_V1.tif",
  "2001-10-10": "clip/bernalillo/2001/USHAP_BC_D1K_20011010_V1.tif",
  "2001-10-11": "clip/bernalillo/2001/USHAP_BC_D1K_20011011_V1.tif",
  "2001-10-12": "clip/bernalillo/2001/USHAP_BC_D1K_20011012_V1.tif",
  "2001-10-13": "clip/bernalillo/2001/USHAP_BC_D1K_20011013_V1.tif",
  "2001-10-14": "clip/bernalillo/2001/USHAP_BC_D1K_20011014_V1.tif",
  "2001-10-15": "clip/bernalillo/2001/USHAP_BC_D1K_20011015_V1.tif",
  "2001-10-16": "clip/bernalillo/2001/USHAP_BC_D1K_20011016_V1.tif",
  "2001-10-17": "clip/bernalillo/2001/USHAP_BC_D1K_20011017_V1.tif",
  "2001-10-18": "clip/bernalillo/2001/USHAP_BC_D1K_20011018_V1.tif",
  "2001-10-19": "clip/bernalillo/2001/USHAP_BC_D1K_20011019_V1.tif",
  "2001-10-20": "clip/bernalillo/2001/USHAP_BC_D1K_20011020_V1.tif",
  "2001-10-21": "clip/bernalillo/2001/USHAP_BC_D1K_20011021_V1.tif",
  "2001-10-22": "clip/bernalillo/2001/USHAP_BC_D1K_20011022_V1.tif",
  "2001-10-23": "clip/bernalillo/2001/USHAP_BC_D1K_20011023_V1.tif",
  "2001-10-24": "clip/bernalillo/2001/USHAP_BC_D1K_20011024_V1.tif",
  "2001-10-25": "clip/bernalillo/2001/USHAP_BC_D1K_20011025_V1.tif",
  "2001-10-26": "clip/bernalillo/2001/USHAP_BC_D1K_20011026_V1.tif",
  "2001-10-27": "clip/bernalillo/2001/USHAP_BC_D1K_20011027_V1.tif",
  "2001-10-28": "clip/bernalillo/2001/USHAP_BC_D1K_20011028_V1.tif",
  "2001-10-29": "clip/bernalillo/2001/USHAP_BC_D1K_20011029_V1.tif",
  "2001-10-30": "clip/bernalillo/2001/USHAP_BC_D1K_20011030_V1.tif",
  "2001-10-31": "clip/bernalillo/2001/USHAP_BC_D1K_20011031_V1.tif",
  "2001-11-01": "clip/bernalillo/2001/USHAP_BC_D1K_20011101_V1.tif",
  "2001-11-02": "clip/bernalillo/2001/USHAP_BC_D1K_20011102_V1.tif",
  "2001-11-03": "clip/bernalillo/2001/USHAP_BC_D1K_20011103_V1.tif",
  "2001-11-04": "clip/bernalillo/2001/USHAP_BC_D1K_20011104_V1.tif",
  "2001-11-05": "clip/bernalillo/2001/USHAP_BC_D1K_20011105_V1.tif",
  "2001-11-06": "clip/bernalillo/2001/USHAP_BC_D1K_20011106_V1.tif",
  "2001-11-07": "clip/bernalillo/2001/USHAP_BC_D1K_20011107_V1.tif",
  "2001-11-08": "clip/bernalillo/2001/USHAP_BC_D1K_20011108_V1.tif",
  "2001-11-09": "clip/bernalillo/2001/USHAP_BC_D1K_20011109_V1.tif",
  "2001-11-10": "clip/bernalillo/2001/USHAP_BC_D1K_20011110_V1.tif",
  "2001-11-11": "clip/bernalillo/2001/USHAP_BC_D1K_20011111_V1.tif",
  "2001-11-12": "clip/bernalillo/2001/USHAP_BC_D1K_20011112_V1.tif",
  "2001-11-13": "clip/bernalillo/2001/USHAP_BC_D1K_20011113_V1.tif",
  "2001-11-14": "clip/bernalillo/2001/USHAP_BC_D1K_20011114_V1.tif",
  "2001-11-15": "clip/bernalillo/2001/USHAP_BC_D1K_20011115_V1.tif",
  "2001-11-16": "clip/bernalillo/2001/USHAP_BC_D1K_20011116_V1.tif",
  "2001-11-17": "clip/bernalillo/2001/USHAP_BC_D1K_20011117_V1.tif",
  "2001-11-18": "clip/bernalillo/2001/USHAP_BC_D1K_20011118_V1.tif",
  "2001-11-19": "clip/bernalillo/2001/USHAP_BC_D1K_20011119_V1.tif",
  "2001-11-20": "clip/bernalillo/2001/USHAP_BC_D1K_20011120_V1.tif",
  "2001-11-21": "clip/bernalillo/2001/USHAP_BC_D1K_20011121_V1.tif",
  "2001-11-22": "clip/bernalillo/2001/USHAP_BC_D1K_20011122_V1.tif",
  "2001-11-23": "clip/bernalillo/2001/USHAP_BC_D1K_20011123_V1.tif",
  "2001-11-24": "clip/bernalillo/2001/USHAP_BC_D1K_20011124_V1.tif",
  "2001-11-25": "clip/bernalillo/2001/USHAP_BC_D1K_20011125_V1.tif",
  "2001-11-26": "clip/bernalillo/2001/USHAP_BC_D1K_20011126_V1.tif",
  "2001-11-27": "clip/bernalillo/2001/USHAP_BC_D1K_20011127_V1.tif",
  "2001-11-28": "clip/bernalillo/2001/USHAP_BC_D1K_20011128_V1.tif",
  "2001-11-29": "clip/bernalillo/2001/USHAP_BC_D1K_20011129_V1.tif",
  "2001-11-30": "clip/bernalillo/2001/USHAP_BC_D1K_20011130_V1.tif",
  "2001-12-01": "clip/bernalillo/2001/USHAP_BC_D1K_20011201_V1.tif",
  "2001-12-02": "clip/bernalillo/2001/USHAP_BC_D1K_20011202_V1.tif",
  "2001-12-03": "clip/bernalillo/2001/USHAP_BC_D1K_20011203_V1.tif",
  "2001-12-04": "clip/bernalillo/2001/USHAP_BC_D1K_20011204_V1.tif",
  "2001-12-05": "clip/bernalillo/2001/USHAP_BC_D1K_20011205_V1.tif",
  "2001-12-06": "clip/bernalillo/2001/USHAP_BC_D1K_20011206_V1.tif",
  "2001-12-07": "clip/bernalillo/2001/USHAP_BC_D1K_20011207_V1.tif",
  "2001-12-08": "clip/bernalillo/2001/USHAP_BC_D1K_20011208_V1.tif",
  "2001-12-09": "clip/bernalillo/2001/USHAP_BC_D1K_20011209_V1.tif",
  "2001-12-10": "clip/bernalillo/2001/USHAP_BC_D1K_20011210_V1.tif",
  "2001-12-11": "clip/bernalillo/2001/USHAP_BC_D1K_20011211_V1.tif",
  "2001-12-12": "clip/bernalillo/2001/USHAP_BC_D1K_20011212_V1.tif",
  "2001-12-13": "clip/bernalillo/2001/USHAP_BC_D1K_20011213_V1.tif",
  "2001-12-14": "clip/bernalillo/2001/USHAP_BC_D1K_20011214_V1.tif",
  "2001-12-15": "clip/bernalillo/2001/USHAP_BC_D1K_20011215_V1.tif",
  "2001-12-16": "clip/bernalillo/2001/USHAP_BC_D1K_20011216_V1.tif",
  "2001-12-17": "clip/bernalillo/2001/USHAP_BC_D1K_20011217_V1.tif",
  "2001-12-18": "clip/bernalillo/2001/USHAP_BC_D1K_20011218_V1.tif",
  "2001-12-19": "clip/bernalillo/2001/USHAP_BC_D1K_20011219_V1.tif",
  "2001-12-20": "clip/bernalillo/2001/USHAP_BC_D1K_20011220_V1.tif",
  "2001-12-21": "clip/bernalillo/2001/USHAP_BC_D1K_20011221_V1.tif",
  "2001-12-22": "clip/bernalillo/2001/USHAP_BC_D1K_20011222_V1.tif",
  "2001-12-23": "clip/bernalillo/2001/USHAP_BC_D1K_20011223_V1.tif",
  "2001-12-24": "clip/bernalillo/2001/USHAP_BC_D1K_20011224_V1.tif",
  "2001-12-25": "clip/bernalillo/2001/USHAP_BC_D1K_20011225_V1.tif",
  "2001-12-26": "clip/bernalillo/2001/USHAP_BC_D1K_20011226_V1.tif",
  "2001-12-27": "clip/bernalillo/2001/USHAP_BC_D1K_20011227_V1.tif",
  "2001-12-28": "clip/bernalillo/2001/USHAP_BC_D1K_20011228_V1.tif",
  "2001-12-29": "clip/bernalillo/2001/USHAP_BC_D1K_20011229_V1.tif",
  "2001-12-30": "clip/bernalillo/2001/USHAP_BC_D1K_20011230_V1.tif",
  "2001-12-31": "clip/bernalillo/2001/USHAP_BC_D1K_20011231_V1.tif"
}

//